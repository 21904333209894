import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import BlogPostPreviewList from '../../components/blog-post-preview-list'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, ContainerTwoCol, ContainerContent } from '../../containers'
import Hero from '../../components/hero'
import PortableText from '../../components/portableText'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Tooltip from '@material-ui/core/Tooltip';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { MdHelpOutline, MdHelp, MdRateReview } from "react-icons/md";
import ReactSpeedometer from "react-d3-speedometer"

import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer, Legend
} from 'recharts';
import { DiscussionEmbed } from 'disqus-react'
import { Input } from '@material-ui/core';
import savingsRateImg from '../../assets/fire-savings-rate.png'
import Sidebar from '../../components/Sidebar'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query SavingsRateCalcPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
      metaImage {
        ...SanityImage
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
          fixed(width: 400) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          authors{
            author{
              name
              id
            }
          }
          mainImage {
            asset{
              fluid {
                ...GatsbySanityImageFluid
              }
              fixed(width: 400) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorGrid = styled(ContainerMain)`
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 3fr;
  grid-gap: 48px;
  align-items: start;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
    margin: 0;
  }

  p {
    font-size: 0.9rem;
  }
`

const GraphDesktop = styled.div`
  display: block;

  @media (max-width: 600px) {
    display: none;
  }
`

const GraphMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }
  
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const InputItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 12px;
  align-items: center;
  margin: 0 0 24px 0;
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }
`

const Label = styled.h3`
  margin: 4px 0 0 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SavingsRateDisplay = styled.div`
  border: 1px solid ${props => props.theme.theme.colors.green};
  background-color: ${props => props.theme.theme.colors.transparentGreen};
  border-radius: 4px;
  padding: 16px;
  font-size: 1rem;
`

const SavingsRateDisplayRed = styled(SavingsRateDisplay)`
  background-color: ${props => props.theme.theme.colors.transparentRed};
  border: 1px solid ${props => props.theme.theme.colors.red};

`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

const SpeedometerContainer = styled.div`
  height: 300px;
  max-width: 500px;

  @media (max-width: 600px) {
    height: 200px;
  }

  .segment-value, .current-value {
    fill: ${props => props.theme.theme.text.primary} !important;
  }

  .pointer {
    fill: ${props => props.theme.theme.text.primary} !important;
  }
`

const Image = styled.img`
  width: 100%;
`

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SavingsRateCalc = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const posts = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const [ monthlyIncome, setMonthlyIncome ] = useState()
  const [ monthlySpending, setMonthlySpending ] = useState()

  const [ currentAge, setCurrentAge ] = useState(30)
  const [ retirementAge, setRetirementAge ] = useState(67)
  const [ annualSpend, setAnnualSpend ] = useState(30000)
  const [ investmentRate, setInvestmentRate ] = useState(7)
  const [ inflationRate, setInflationRate ] = useState(3)
  const [ currentNetWorth, setCurrentNetWorth ] = useState(100000)
  const [ SWR, setSWR ] = useState(4)
  const [ payment, setPayment ] = useState(500)
  const [ annualCompoundingPeriods, setAnnualCompoundingPeriods ] = useState(1)
  const [ annualPaymentPeriods, setAnnualPaymentPeriods ] = useState(12)

  const savingsRate =  (monthlyIncome - monthlySpending) / monthlyIncome

  const savingsRateAdjusted = savingsRate > 0 ? savingsRate : 0

  let Dialog

  if(isNaN(savingsRate)){
    Dialog = <SavingsRateDisplay>Please enter your monthly income and spending</SavingsRateDisplay>
  } else {
    if(savingsRate >= 0){
      Dialog =
      <SavingsRateDisplay>
        Your Savings Rate: <strong>{`${(savingsRate*100).toFixed(2)}%`}</strong>
      </SavingsRateDisplay>
    } else{
      Dialog = <SavingsRateDisplayRed>
        Yikes! Your spending exceeds your income.
        You need to lower your spending to spend less than you earn.
      </SavingsRateDisplayRed>
    }
  }

  console.log(savingsRateImg)

  return (
    <Layout>
      <SEO
        title={'Savings Rate Calculator'}
        description={'Use this simple calculator to determine your personal savings rate'}
        keywords={site.keywords}
      />
      <ReactTooltip />
      <ContainerMain>
        <h1>Savings Rate Calculator</h1>
        <H2>Use this simple calculator to determine your personal savings rate</H2>
      </ContainerMain>

      <CalculatorGrid>
        <form>
          <InputItem>
            <ThemedTextarea
              id="outlined-name"
              label="Monthly Income"
              value={monthlyIncome}
              onChange={event => setMonthlyIncome(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            <MdHelp size="24px"
              data-tip={`This is your monthly take-home pay after all taxes.
              This DOES include any contributions you make to retirement accounts like a 401k or HSA.`}
            />

          </InputItem>

          <InputItem>
            <ThemedTextarea
              id="outlined-name"
              label="Monthly Spending"
              value={monthlySpending}
              onChange={event => setMonthlySpending(event.target.value)}
              variant="outlined"
              fullWidth
              InputProps={{
                inputComponent: NumberFormatCustom,
              }}
            />
            <MdHelp size="24px"
              data-tip={`This is your total monthly spending including things like rent / mortgage,
              insurance, transportation, food, entertainment, etc.`}
            />
          </InputItem>

          {Dialog}

        </form>

        <SpeedometerContainer>
          <ReactSpeedometer
            maxSegmentLabels={10}
            segments={10}
            fluidWidth={true}
            value={savingsRateAdjusted}
            minValue={0}
            maxValue={1}
            valueFormat={`.0%`}
            ringWidth={75}
            needleColor={'000'}
            segmentColors={['#E33124', '#FF9800', '#FFEB3B', '#CDDC39', '#8BC34A', '#4CAF50', '#3F9342', '#308234', '#308234', '#308234']}
            />
        </SpeedometerContainer>
      </CalculatorGrid>

      <ContainerTwoCol>
        <ContainerContent>
          <h2>What is my Savings Rate?</h2>
          <p>
            Your personal savings rate is a measure of how much of your personal disposable income is saved rather than spent.
            It is the ratio of your personal savings divided by disposable income over a given period and is typically written as a percentage.
            Your disposable income should be used to calculate savings rate and this is your total income after all income taxes.
          </p>

          <p>
            The higher your savings rate, the stronger your personal financial situation.
            A savings rate of 0% means that you spend all of your income and save none of your income.
            On the other hand, a savings rate of 100% means that you save all of your income and spend none of your income.
          </p>
          <h2>How do I calculate my Savings Rate?</h2>
          <p>
            Savings Rate (SR) is defined as the ratio of savings divided by your income. Your savings over any period is your income - expenses.
            Thus your SR = (Income after tax - spending) / (Income after tax). To convert this SR to a percentage, multiply by 100.
          </p>

          <h2>Using this calculator</h2>
          <p>
            This interactive calculator makes it easy to calculate and visualize your personal savings rate.
            Simply input your monthly take-home pay and monthly spending.
          </p>

          <h2>What is a good savings rate?</h2>
          <p>
            As a rule, the higher your savings rate, the faster that you can achieve financial independence.
            What is a "good" savings rate depends highly on your individual situation and how much you are able to save.
            At a minimum, you should aim to have a savings rate of 20%.
            While you should strive to maintain as high a savings rate as possible, you should still allow yourself to spend enough to enjoy life.
            According to the <a href="https://fred.stlouisfed.org/series/PSAVERT" target="_blank">Federal Reserve Bank of St Louis</a>,
            the personal savings rate across the USA was about 14.3% as of September 2020.
          </p>

          <h2>Savings rate and time to financial independence (FIRE)</h2>
          <p>
            Regardless of how high your income or your expenses, your savings rate alone determines how soon you can reach FIRE.
            Mr. Money Mustache detailed this concept in his infamous article, <a href="https://www.mrmoneymustache.com/2012/01/13/the-shockingly-simple-math-behind-early-retirement/" target="_blank">
              The Shockingly Simple Math Behind Early Retirement
            </a>. If your savings rate is 0%, then you will never save up enough to retire, as you are spending 100% of your income.
            Conversely, if your savings rate is 100%, then you spend none of your income and you would be considered Financially Independent (FI).
          </p>

          <p>
            The table and graph below illustrate the relationship between savings rate and time to FI.
            To generate these numbers, I used the WalletBurst <Link to="/tools/fire-calculator">FIRE Calculator</Link> with
            a starting net worth of $0, a 100% allocation into stocks, an 8% rate of return, and 3% inflation rate.
            Looking at the graph, it's interesting to note that the curve is not linear but rather exponential.
            The curve is steepest from 0% to about a 25% savings rate, and beyond a 25% savings rate, it becomes roughly linear.
            The big takeaway from the graph is that the biggest improvements to time-to-FIRE are at lower savings rates.
            Increasing your savings rate from 10% to 20% speeds up your time-to-FIRE by 15 years, while going from 50% to 60% only
            gets you 4 years closer to FIRE.
          </p>
          <Image src={savingsRateImg} style={{maxWidth: '800px'}}/>
        </ContainerContent>
        <Sidebar />

      </ContainerTwoCol>
      <Backdrop>
        <ContainerMain>
          <DiscussionEmbed
              shortname={process.env.GATSBY_DISQUS_NAME}
              config={{
                  identifier: 'savings-rate-calculator'
              }}
          />
        </ContainerMain>
      </Backdrop>
    </Layout>
  )
}

export default SavingsRateCalc
